<template>
    <v-dialog v-model="displayUnsubscribe" persistent width="700">
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                    <div class="d-flex align-center">
                        <v-toolbar-title class="d-flex align-center">{{$t("exportUnsubscribeContacts")}}</v-toolbar-title>
                    </div>
                    <div>
                        <v-btn outlined dark depressed @click.native="$emit('closeUnsubscribe')">{{  $t('cancel') }}</v-btn>
                        <v-btn outlined dark depressed :loading="loading" @click="launchExport" class="ml-3">{{ $t('export') }}</v-btn>
                    </div>
                </div> 
            </v-toolbar>
            <v-card-text class="pt-4">
                <VDatePicker v-if="!operationId" :model="model" :fieldName="'dateFrom'" :field="{title: 'dateFrom', props:{required:true}}" />
                <div class="input-form">
                    <span class="label"><b>{{$t('chooseCanal')}}</b><span style="color:red">&nbsp;*</span></span>
                    <v-select :items="possibleCanal" required dense solo flat v-model="model.canal"></v-select>
                </div>
                <div class="">
                    <span class="input-form"><span class="label "><b>{{$t('exportOption')}}</b></span></span>
                    <v-checkbox v-for="(value, field) in model.options" :key="'field_'+field" hide-details v-model="model.options[field]" :label="$t(field)" dense></v-checkbox>
                </div>
                <v-dialog persistent width="400" v-model="showDownload">
                    <v-card>
                        <v-card-text class="pa-6">
                            <h5 class="icon-title">
                            {{$t('downloadReady')}}
                            <v-btn @click="showDownload = false" text class="square">
                                <v-icon small>$close</v-icon>
                            </v-btn>
                            </h5>
                            <div class="text-center mt-4 mb-2">
                            <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                            </div>
                            
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import VDatePicker from "@/components/forms/inputs/v-date-picker";
export default {
    name: "exportUnsubscribeContacts",
    props: ['displayUnsubscribe', 'operationId'],
    components: {VDatePicker},
    data() {
        return {
            menu:false,
            api: this.$store.state.auth.apiUrl,
            possibleCanal:['EMAIL', 'SMS'],
            loading: false,
            downloadUrl: '',
            showDownload:false,
            model: {
                dateFrom:"",
                canal: "EMAIL",
                options: {
                    contact_id: false,
                    unsubscribe_source:false,
                    rp_source : false
                }
            }
            
        }
    },
    created() {
        if(this.operationId){
            this.model.operation_id = this.operationId;
            delete this.model.dateFrom
        } else {
            let date = new Date(new Date().setMonth(new Date().getMonth()-1));
            let month = (date.getMonth()+1);
            if(month < 10)
                month = '0'+month;
            date = date.getFullYear()+'-'+month+"-"+date.getDate()
            this.model.dateFrom = date
        }
        
    },
    mounted(){
        if(this.operationId){
            this.model.operation_id = this.operationId;
            delete this.model.dateFrom
        } else {
            let date = new Date(new Date().setMonth(new Date().getMonth()-1));
            let month = (date.getMonth()+1);
            if(month < 10)
                month = '0'+month;
            date = date.getFullYear()+'-'+month+"-"+date.getDate()
            this.model.dateFrom = date
        }
    },
    methods: {
        launchExport(){
            this.loading = true;
            GenericDataService.postData('/contact/exportUnsubscribeContacts', this.model).then((response)=> {
                this.loading = false
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload
                    this.showDownload = true;
                }
            }).catch((error) => {
                this.loading = false
            });
        }
    },
}
</script>
<style lang="scss">
    
</style>