<template>
    <v-dialog v-model="displayExport" persistent width="700">
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                    <div class="d-flex align-center">
                        <v-toolbar-title class="d-flex align-center">{{$t("displayExport")}}</v-toolbar-title>
                    </div>
                    <div>
                        <v-btn outlined dark depressed @click.native="$emit('closeModal')">{{  $t('cancel') }}</v-btn>
                        <v-btn outlined dark depressed :loading="loading" @click="launchExport" class="ml-3">{{ $t('export') }}</v-btn>
                    </div>
                </div> 
            </v-toolbar>
            <v-card-text class="pt-4">
                <!-- <VDatePicker v-if="!operationId" :model="model" :fieldName="'dateFrom'" :field="{title: 'dateFrom', props:{required:true}}" /> -->
                <v-form ref="blacklistForm" v-model="valid">
                    <div class="input-form mb-2">
                        <span class="label"><b>{{$t('choosePeriod')}}</b><span style="color:red">&nbsp;*</span></span>
                        <VDaterangePicker v-model="model.dateFrom" class="input-form" :props="{solo: true, flat: true, dense: true, required:true, rules: [v => (v && v != '') ? true : $t('periodNotDefined')]}"/>
                    </div>
                    <div class="input-form">
                        <span class="label"><b>{{$t('chooseCanal')}}</b><span style="color:red">&nbsp;*</span></span>
                        <v-select :items="possibleCanal" :rules="[v => (v.length > 0) ? true : $t('canalNotSelected')]" required dense solo flat multiple v-model="model.canal"></v-select>
                    </div>
                </v-form>
                <v-dialog persistent width="400" v-model="showDownload">
                    <v-card>
                        <v-card-text class="pa-6">
                            <h5 class="icon-title">
                            {{$t('downloadReady')}}
                            <v-btn @click="showDownload = false" text class="square">
                                <v-icon small>$close</v-icon>
                            </v-btn>
                            </h5>
                            <div class="text-center mt-4 mb-2">
                            <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                            </div>
                            
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import VDatePicker from "@/components/forms/inputs/v-date-picker";
import VDaterangePicker from "@/components/forms/inputs/v-daterange-picker";
export default {
    name: "exportBlacklist",
    props: ['displayExport', 'operationId'],
    components: {VDatePicker, VDaterangePicker},
    data() {
        return {
            menu:false,
            api: this.$store.state.auth.apiUrl,
            possibleCanal: [
                {
                    text: this.$t('EMAIL'),
                    value: 'EMAIL'
                },
                {
                    text: this.$t('PHONE'),
                    value: 'PHONE'
                }
            ],
            loading: false,
            downloadUrl: '',
            showDownload:false,
            model: {
                dateFrom:{
                    start: '',
                    end: '',
                    preset: 'period'
                },
                canal: ["EMAIL", "PHONE"],
            },
            valid: false
            
        }
    },
    created() {
        if(this.operationId){
            this.model.operation_id = this.operationId;
            delete this.model.dateFrom
        } else {
            let date = new Date(new Date().setMonth(new Date().getMonth()-1));
            let month = (date.getMonth()+1);
            if(month < 10)
                month = '0'+month;
            date = date.getFullYear()+'-'+month+"-"+date.getDate()
            this.model.dateFrom = date
        }
        
    },
    mounted(){
        if(this.operationId){
            this.model.operation_id = this.operationId;
            delete this.model.dateFrom
        } else {
            let date = new Date(new Date().setMonth(new Date().getMonth()-1));
            let month = (date.getMonth()+1);
            if(month < 10)
                month = '0'+month;
            date = date.getFullYear()+'-'+month+"-"+date.getDate()
            this.model.dateFrom = date
        }
    },
    methods: {
        launchExport(){
            if(this.$refs.blacklistForm.validate()){
                this.loading = true;
                GenericDataService.postData('/blacklist/exportBlacklist', this.model).then((response)=> {
                    this.loading = false
                    if(response.data.data.directDownload){
                        this.downloadUrl = this.api + response.data.data.directDownload
                        this.showDownload = true;
                    }
                }).catch((error) => {
                    this.loading = false
                });
            }
        }
    },
}
</script>
<style lang="scss">
    
</style>